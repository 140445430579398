(function ($, Drupal, languageToggle) {
  var footerLanguageMenu = {
    setup: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.init(context);
    },
    init: function (context) {
      var $languageMenu = $('.js-footer-language-menu-v2', context);
      var $menu = $languageMenu.find('.js-footer-language-menu__select');
      var $menuItem = $menu.find('option');
      var $activeSelectboxItem = $();

      /*
      Set dropdown option to currently active language
      */
      $menuItem.each(function () {
        var $option = $(this);
        var optionValue = $option.val();

        if (optionValue === languageToggle.getActiveLanguage()) {
          $option.prop('selected', true);
        }
      });

      $menu.selectBox({
        menuTransition: 'fade',
        menuSpeed: 'fast',
        mobile: false,
        hideOnWindowScroll: true
      });
      $menu.selectBox().change(function () {
        var selectedLanguage = $(this).val();
        languageToggle.setCookie(selectedLanguage);
        languageToggle.switchLanguage(selectedLanguage);
      });

      $activeSelectboxItem = $(
        '.js-footer-language-menu-item a[rel="' + languageToggle.getActiveLanguage() + '"]',
        context
      );
      $activeSelectboxItem.hide();
    }
  };

  Drupal.behaviors.footerLanguageMenuV2 = {
    attached: false,
    attach: function (context) {
      footerLanguageMenu.setup(context);
    }
  };
})(jQuery, Drupal, languageToggle);
